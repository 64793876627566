import {ConsumerProps, Context, createContext, useContext} from 'react';

const defaultValue = Symbol('context default value');
type DefaultValue = Symbol;

export const createSafeContext = <ContextValue>() => {
    return createContext<ContextValue | DefaultValue>(defaultValue);
};

export const useSafeContext = <T>(TheContext: Context<T | DefaultValue>): T => {
    const value = useContext(TheContext);
    if (value === defaultValue) throw new Error('No value provided for context');
    return value as T;
};

export const createSafeConsumer = <T>(TheContext: Context<T | DefaultValue>) => {
    return function SafeConsumer({children}: ConsumerProps<T>) {
        const value = useSafeContext(TheContext);
        return children(value);
    };
};
