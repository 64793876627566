import {ApolloProvider} from '@apollo/client';
import {EmotionCache} from '@emotion/react';
import CssBaseline from '@mui/material/CssBaseline';
import {ThemeProvider} from '@mui/material/styles';
import App, {AppContext, AppProps} from 'next/app';
import Head from 'next/head';
import {useRouter} from 'next/router';
import {useEffect, useMemo} from 'react';
import {ErrorBoundary} from 'react-error-boundary';
import {createIntl, createIntlCache, CustomFormats, RawIntlProvider} from 'react-intl';
import {ErrorFallback} from '../client/common/components/error/ErrorFallback';
import {browserSupoort} from '../client/common/constants';
import {useApollo} from '../client/lib/apolloClient';
import {AppContextProvider} from '../client/lib/context/AppContext';
import {useTheme} from '../client/lib/theme';
import Czech from '../lang/cs.json';
import English from '../lang/en.json';

/* Root stylesheet */
import {AppCacheProvider} from '@mui/material-nextjs/v14-pagesRouter';
import '../public/static/fonts/BrixSans/stylesheet.css';

// This is optional but highly recommended
// since it prevents memory leak
const intlCache = createIntlCache();

type Props = AppProps & {
    readonly token: string;
    readonly locale: string;
    readonly messages: {readonly [key: string]: string};
    readonly formats: CustomFormats | undefined;
    readonly statusCode: number;
    readonly emotionCache?: EmotionCache;
};

const MyApp = (props: Props) => {
    const {Component, pageProps, locale, formats, statusCode} = props;

    const apolloClient = useApollo(pageProps);

    const {theme} = useTheme();

    const {push} = useRouter();

    const [shortLocale] = locale ? locale.split('-') : ['en'];

    const messages = useMemo(() => {
        switch (shortLocale) {
            case 'cs':
                return Czech;
                break;
            case 'en':
                return English;
                break;
            default:
                return Czech;
        }
    }, [shortLocale]);

    useEffect(() => {
        if (!browserSupoort(window.navigator.userAgent)) {
            push('/warning.html');
        }
    });

    return (
        <ApolloProvider client={apolloClient}>
            <RawIntlProvider value={createIntl({locale, messages, formats}, intlCache)}>
                <AppCacheProvider {...props}>
                    <ThemeProvider theme={theme}>
                        <CssBaseline />
                        <ErrorBoundary FallbackComponent={ErrorFallback}>
                            <Head>
                                <meta
                                    name="viewport"
                                    content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no, user-scalable=no, viewport-fit=cover"
                                />
                                {/* eslint-disable-next-line i18next/no-literal-string */}
                                <title>Formulář pro změnu/potvrzení termínu servisní práce na odběrném místě</title>
                            </Head>
                            {/* <ClickToComponent editor="vscode" /> */}
                            <AppContextProvider>
                                <Component {...pageProps} statusCode={statusCode} />
                            </AppContextProvider>
                        </ErrorBoundary>
                    </ThemeProvider>
                </AppCacheProvider>
            </RawIntlProvider>
        </ApolloProvider>
    );
};

const getInitialProps = async (appContext: Partial<AppContext>) => {
    const {ctx} = appContext;

    const [appProps] = await Promise.all([App.getInitialProps(appContext as AppContext)]);

    const statusCode = ctx?.res?.statusCode;

    const formats = getFormats();

    return {
        ...appProps,
        statusCode,
        formats,
        locale: getLocales(ctx?.req?.headers['accept-language']),
    };
};

MyApp.getInitialProps = getInitialProps;

const getLocales = (str: string | undefined) => {
    return str?.split(',').map((type) => type.split(';')[0]?.trim().replace('*', ''))[0] ?? 'cs';
};

const getFormats = () => ({
    number: {
        CS: {
            style: 'currency',
            currency: 'CZK',
        },
        EN: {
            style: 'currency',
            currency: 'US',
        },
    },
});

export default MyApp;
