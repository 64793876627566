import {ServisniZakazkyGet} from '@eon.cz/apollo13-graphql-web';
import {ReactElement, ReactNode, useCallback, useMemo, useState} from 'react';
import {NotificationModel, NotificationType} from '../../common/components/notifications/NotificationModel';
import {createSafeConsumer, createSafeContext, useSafeContext} from './helpers';

export type NotificationAddRequest = {
    // Notification type. When not specified, INFO is assumed.
    readonly type?: NotificationType;

    // Notification text or node (for rich notifications).
    readonly text: ReactNode;

    // Error object for optional display
    readonly errorObject?: any;

    // Auto-hide time in milliseconds. Use -1 to never autohide. When not specified, time is determined automatically.
    readonly autohideTime?: number;
};
type ContextValue = {
    readonly values: {
        readonly pin?: string;
        readonly notifications?: NotificationModel[];
        readonly servisniZakazky?: ServisniZakazkyGet;
    };
    readonly setContextValues?: (value: ContextValue['values']) => void;
    readonly addNotification: (notification: NotificationAddRequest) => void;
    readonly closeNotification: () => void;
};

export type ContextValueType = ContextValue['values'];

export const AppContext = createSafeContext<ContextValue>();

export const useAppContext = () => useSafeContext(AppContext);
export const AppContextConsumer = createSafeConsumer(AppContext);

export function AppContextProvider({children}: {children: ReactNode}): ReactElement {
    const [values, setValues] = useState<ContextValue['values']>({
        pin: undefined,
        notifications: [],
    });

    const addNotification = (notification: NotificationAddRequest) => {
        setValues((prevState) => ({
            ...prevState,
            notifications: [notification],
        }));
    };

    const closeNotification = () => {
        setValues((prevState) => ({...prevState, notifications: prevState.notifications?.slice(1)}));
    };

    const setContextValues = useCallback(
        (value: ContextValue['values']) =>
            setValues((prevState) => ({
                ...prevState,
                ...value,
            })),
        [],
    );

    const value = useMemo(
        () => ({
            values,
            setContextValues,
            addNotification,
            closeNotification,
        }),
        [values],
    );

    return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
}
